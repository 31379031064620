
.data-content {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.show-content {
    flex: 1;
    display: flex;
    justify-content: flex-end;

    .top-tips {
        color: #fff;
        margin-right: 20px;
    }

    .main-content {
        width: 400px;
        height: 460px;
        background: #0c0e3f;
        border-radius: 20px;

        .top-imgBox {
            width: 400px;
            height: 330px;
            position: relative;

            img {
                width: 400px;
                height: 330px;
                object-fit: cover;
            }

            .footer-content {
                position: absolute;
                width: calc(100% - 50px);
                bottom: 0;
                background: #000000;
                opacity: 0.6;
                display: flex;
                align-items: center;
                padding: 25px;
                color: #fff;
                font-size: 16px;

                i {
                    font-size: 24px;
                    margin-right: 13px;
                }
            }
        }

        .bottom-box {
            padding: 20px;
            color: #fff;

            .bottom-title {
                font-size: 18px;
            }

            .bottom-imgBox {
                margin-top: 13px;
                display: flex;
                align-items: center;

                img {
                    width: 54px;
                    height: 54px;
                    border-radius: 50%;
                }

                .bottom-name {
                    margin-left: 10px;
                    font-size: 16px;
                }
            }
        }
    }
}

.el-form {
    ::v-deep .el-form-item {
        .el-form-item__label {
            color: #fff;
        }

        &.is-required {
            .el-form-item__label {
                position: relative;

                &::before {
                    position: absolute;
                    right: -15px;
                }
            }
        }
    }
}

.el-radio {
    display: flex;
    line-height: 16px;
    color: #fff;

    ::v-deep .el-radio__inner {
        background: #0c0e3f;
        border: 1px solid #262963;
    }

    &.is-checked {
        ::v-deep .el-radio__inner {
            border: 3px solid #4b45ff;

            &::after {
                content: unset;
            }
        }

        ::v-deep .el-radio__label {
            color: #fff;
        }
    }
}

.el-radio + .el-radio {
    margin-top: 20px;
}

.label-tips {
    margin-left: 10px;
    opacity: 0.2;
    color: #fff;
}

.dark-input {
    width: 380px;

    ::v-deep .el-input__inner {
        background: #0c0e3f;
        border: unset;
        color: #fff;

        &:focus {
            border: 1px solid #4b45ff;
        }
    }
}

.dark-text {
    width: 800px;

    ::v-deep .el-textarea__inner {
        background: #0c0e3f;
        border: unset;
        color: #fff;

        &:focus {
            border: 1px solid #4b45ff;
        }
    }
}

.upload-content {
    display: flex;

    .upload-tips {
        color: #fff;
        opacity: 0.2;
        display: flex;
        align-items: center;
        margin: 0 96px 0 20px;
    }

    .img-box {
        width: 100px;
        height: 100px;
        position: relative;

        .shadow-box {
            position: absolute;
            bottom: 0;
            background: #000000;
            opacity: 0.6;
            border-radius: 4px;
            width: 100px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            font-size: 12px;
            cursor: pointer;
        }

        img {
            width: 100px;
            height: 100px;
        }
    }

    .img-uploader {
        width: 100px;
        height: 100px;
        background: #0c0e3f;
        border: 1px solid #262963;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        position: relative;
        border-radius: 4px;

        .add-box {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            line-height: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .add-icon {
                color: #4b45ff;
                font-size: 24px;
            }
        }
    }
}
